
import Vue from 'vue'
import { Component, Prop, Model } from 'nuxt-property-decorator'

/**
 * Компонент select инпута
 */
@Component({
  name: 'UiSelect'
})
export default class UiSelect extends Vue {
  /**
   * *________________ Model ______________________
   */
  @Model('change')

  /**
   * *________________ Props ______________________
   */
  /**
   * Значение
   */
  @Prop({
    type: [Boolean, Number, String, Array],
    required: false
  })
    value!: boolean | number | string | []

  /**
   * popper-append-to-body
   */
  @Prop({
    type: Boolean,
    default: true,
    required: false
  })
    popperAppendToBody!: boolean

  /**
   * Ключ свойства значения опций
   */
  @Prop({
    type: String,
    required: false
  })
    valueKey!: string

  @Prop({
    type: Function,
    required: false
  })
    remoteMethod!: () => {}

  /**
   * Лоадер
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    vLoading!: boolean

  /**
   * Размер компонента
   */
  @Prop({
    type: String,
    required: false,
    default: 'middle'
  })
    size!: String

  /**
   * Анимация лоадера
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
    elementLoadingSpinner!: string

  /**
   * Статус множественного выбора опций
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    multiple!: boolean

  /**
   * Статус сброса выбранных опций
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    clearable!: boolean

  /**
   * Плейсхолдер компонента
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
    placeholder!: string

  /**
   * Конфигурация компонента
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {}
  })
    selectProps!: any

  /**
   * Статус блокировки комопнента
   */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    disabled!: boolean

  /**
   * Список опций
   */
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    optionList!: Array<any>

  /**
   * Ключ свойства сортировки опций
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
    optionSort!: string

  /**
   * Порядок сортировки опций
   */
  @Prop({
    type: String,
    required: false,
    default: 'asc'
  })
    optionOrder!: string

  /**
   * Ключ свойства ключа опции
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
    optionKey!: string

  /**
   * Функция генерации ключа опции
   */
  @Prop({
    type: Function,
    required: false,
    default: (_option: any) => { return null }
  })
    optionKeyFunc!: (option: any, idx?: number) => null

  /**
   * Ключ свойства значения опции
   */
  @Prop({
    type: String,
    required: false
  })
    optionValue!: string

  /**
   * Ключ свойства названия опции
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
    optionLabel!: string

  /**
   * Функция генерации названия опции
   */
  @Prop({
    type: Function,
    required: false,
    default: (_option: any, _idx?: number) => { return null }
  })
    optionLabelFunc!: (option: any) => null

  /**
   * Функция генерации статуса блокировки опции
   */
  @Prop({
    type: Function,
    required: false,
    default: (_option: any) => { return null }
  })
    optionDisabledFunc!: (option: any) => null

  @Prop({
    type: Boolean,
    default: false
  })
    iterateIds!: boolean

  @Prop({
    type: Function,
    required: false,
    default: (option: any) => { return option }
  })
    optionFilterFunc!: (option: any) => null

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    hidePrefix!: boolean

  /**
   * Множитель порядка сортировки опций
   */
  get orderMod () {
    switch (this.optionOrder) {
      case 'desc':
        return -1
      default:
        return 1
    }
  }

  /**
   * Массив отсортированных опций
   */
  get proceededOptions () {
    return (
      this.sortOptions(
        this.filterOptions(
          this.optionList
        )
      )
    )
  }

  //* Получение айдишников опций
  get proceededOptionsIds () {
    return this.proceededOptions?.map(el => el?.id) || []
  }

  //* Проверка - если нужна итерация по айдишникам, то возвращаем ее - в ином случае обычный список опций
  get iterateOptions () {
    return this.iterateIds ? this.proceededOptionsIds : this.proceededOptions
  }

  //* Получение опции в зависимости от флага итерации
  getParam (pl: any) {
    return (this.iterateIds && this.optionList?.find(el => el?.id === pl)) || pl
  }

  sortOptions (optionList: any[]) {
    if (!this.optionSort) { return optionList }

    if (typeof optionList?.[0]?.[this.optionSort] === 'number') {
      return optionList.slice().sort((a, b) => (a[this.optionSort] - b[this.optionSort]) * this.orderMod)
    }

    if (this.optionSort === '_') {
      return optionList.slice().sort((a: any, b: any) => a.localeCompare(b) * this.orderMod)
    }

    return optionList.slice().sort((a: any, b: any) => a?.[this.optionSort].localeCompare(b?.[this.optionSort]) * this.orderMod)
  }

  filterOptions (optionList: any[]) {
    if (!this.optionFilterFunc) { return optionList }
    return optionList?.filter(option => this.optionFilterFunc(option))
  }
}
