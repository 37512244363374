import BannerBlocksMixin from './modules/banner-blocks/banner-blocks-mixin'
import ChatChannelsMixin from './modules/chat-channels/mixin'
import DefaultDeliveryRegionsMixin from './modules/delivery-regions/delivery-regions-mixin'
import OrdersStatusesStatisticsMixin from './modules/orders-statuses-statistics/orders-statuses-statistics-mixin'
import Newsletters from '~/store/modules/newsletters/newsletters-mixin'
import OptionsValuesMixin from '~/store/modules/options-values/options-values-mixin'
import StickersMixin from '~/store/modules/stickers/stickers-mixin'
import OrdersMixin from '~/store/modules/orders/orders-mixin'
import ReindexerMixin from '~/store/modules/reindexer/reindexer-mixin'
import SitesMixin from '~/store/modules/sites/sites-mixin'
import RolesMixin from '~/store/modules/roles/roles-mixin'
import BasicReportMixin from '~/store/modules/basic-report/basic-report-mixin'
import PriceRolesMixin from '~/store/modules/price-roles/price-roles-mixin'
import CategoriesMixin from '~/store/modules/categories/categories-mixin'
import FormsMixin from '~/store/modules/forms/forms-mixin'
import ReviewsMixin from '~/store/modules/reviews/reviews-mixin'
import FormParamsMixin from '~/store/modules/form-params/form-params-mixin'
import PagesMixin from '~/store/modules/page-mixin'
import ProductsMixin from '~/store/modules/products/products-mixin'
import BasketsMixin from '~/store/modules/baskets/baskets-mixin'
import NewsMixin from '~/store/modules/news/news-mixin'
import ListWaitingMixin from '~/store/modules/list-waiting/list-waiting-mixin'
import WidgetsMixin from '~/store/modules/widgets/widgets-mixin'
import SubscribersMixin from '~/store/modules/subscribers/subscribers-mixin'
import UsersMixin from '~/store/modules/users/users-mixin'
import ProductGroupsMixin from '~/store/modules/product-groups/product-groups-mixin'
import DiscountGroupsMixin from '~/store/modules/discount-groups/discount-groups-mixin'
import ManagersMixin from '~/store/modules/managers/managers-mixin'
import GroupsParamsMixin from '~/store/modules/groupsParams/groups-params-mixin'
import DeliveryGroupsMixin from '~/store/modules/delivery-groups/delivery-groups-mixin'
import DeliveryMixin from '~/store/modules/delivery/delivery-mixin'
import DeliveryPricesMixin from '~/store/modules/delivery-prices/delivery-prices-mixin'
import SeoPagesMixin from '~/store/modules/seo-pages/seo-pages-mixin'
import WordCasesMixin from '~/store/modules/word-cases/word-cases-mixin'
import WordAdjectivesMixin from '~/store/modules/word-adjectives/word-adjectives-mixin'
import TemplatesMixin from '~/store/modules/templates/templates-mixin'
import WordPluralsMixin from '~/store/modules/word-plurals/word-plurals-mixin'
import WordReplacesMixin from '~/store/modules/word-replaces/word-replaces-mixin'
import GenerateMixin from '~/store/modules/generate/generate-mixin'
import RobotsMixin from '~/store/modules/robots/robots-mixin'
import EmailTemplatesMixin from '~/store/modules/email-templates/email-templates-mixin'
import PointsMixin from '~/store/modules/points/points-mixin'
import RegionsMixin from '~/store/modules/regions/regions-mixin'
import PaymentsMixin from '~/store/modules/payments/payments-mixin'
import OrderStatusGroupsMixin from '~/store/modules/order-status-groups/order-status-groups-mixin'
import StatusQualityMixin from '~/store/modules/status-quality/status-quality-mixin'
import OrderStatusesMixin from '~/store/modules/order-statuses/order-statuses-mixin'
import ConfigMixin from '~/store/modules/config/config-mixin'
import PartnersMixin from '~/store/modules/partners/partners-mixin'
import CurrenciesMixin from '~/store/modules/currencies/currencies-mixin'
import PriceRoleAssignmentsMixin from '~/store/modules/price-role-assignment/price-role-assignment-mixin'
import ProductFixedMixin from '~/store/modules/product-fixed/product-fixed-mixin'
import OrderBillsMixin from '~/store/modules/order-bills/order-bills-mixin'
import OrderOffersMixin from '~/store/modules/order-offers/order-offers-mixin'
import ProductOffersMixin from '~/store/modules/product-offers/product-offers-mixin'
import OfferMixin from '~/store/modules/offer/offer-mixin'
import MainPageMixin from '~/store/modules/main-page/main-page-mixin'
import StaticPagesMixin from '~/store/modules/pages/pages-mixin'
import PermissionsMixin from '~/store/modules/permissions/permisions-mixin'
import BonusMixin from '~/store/modules/bonus/bonus-mixin'
import BonusAccountMixin from '~/store/modules/bonus-account/bonus-account-mixin'
import BonusLogMixin from '~/store/modules/bonus-log/bonus-log-mixin'
import BonusTypeMixin from '~/store/modules/bonus-type/bonus-type-mixin'
import SeoTextsMixin from '~/store/modules/seo-texts/seo-texts-mixin'
import FeedsMixin from '~/store/modules/feeds/feeds-mixin'
import FilesMixin from '~/store/modules/files/files-mixin'
import PromoCodesMixin from '~/store/modules/promo-codes/promo-codes-mixin'
import CustomFiltersMixin from '~/store/modules/custom-filters/custom-filters-mixin'
import SearchTextReplaceMixin from '~/store/modules/search-text-replace/search-text-replace-mixin'
import UserTypesMixin from '~/store/modules/users-type/user-types-mixin'
import OrderCommentsMixin from '~/store/modules/order-comments/oder-comments-mixin'
import SearchLogsMixin from '~/store/modules/search-logs/search-logs-mixin'
import CollectionsMixin from '~/store/modules/collections/collections-mixin'
import EmailTemplateGroupsMixin from '~/store/modules/email-template-groups/email-template-groups-mixin'
import RafflesMixin from '~/store/modules/raffles/raffles-mixin'
import EventTriggersMixin from '~/store/modules/event-triggers/mixin'
import PageTemplatesMixin from '~/store/modules/page-templates/mixin'
import TasksMixin from '~/store/modules/tasks/store/tasks/mixin'
import TaskStatusesMixin from '~/store/modules/tasks/store/task-statuses/mixin'
import TaskOptionsMixin from '~/store/modules/tasks/store/task-options/mixin'
import TaskUserOptionsMixin from '~/store/modules/tasks/store/task-user-options/mixin'
import ChampionshipTeamProfileFieldsMixin from '~/store/modules/championship/team-profile-fields/mixin'
import ChampionshipCitiesMixin from '~/store/modules/championship/cities/mixin'
import ChampionshipStagesMixin from '~/store/modules/championship/stages/mixin'
import ChampionshipCompetenciesMixin from '~/store/modules/championship/competency/mixin'
import ChampionshipAgeGroupMixin from '~/store/modules/championship/age-group/mixin'
import ChampionshipAgeTypeMixin from '~/store/modules/championship/age-type/mixin'
import ChampionshipActionMixin from '~/store/modules/championship/action/mixin'
import WebsiteAssistantMixin from '~/store/modules/website-assistant/website-assistant-mixin'
import ChampionshipChampionshipsMixin from '~/store/modules/championship/championships/mixin'
import ChampionshipPermissionMixin from '~/store/modules/championship/permission/mixin'
import RefundMixin from '~/store/modules/refund/mixin'
import SurveysMixin from '~/store/modules/surveys/surveys-mixin'

export default {
  OrderCommentsMixin,
  SearchLogsMixin,
  SitesMixin,
  OrdersMixin,
  RolesMixin,
  PriceRolesMixin,
  CategoriesMixin,
  PagesMixin,
  FormsMixin,
  OptionsValuesMixin,
  BannerBlocksMixin,
  StickersMixin,
  ProductsMixin,
  BasketsMixin,
  NewsMixin,
  ListWaitingMixin,
  WidgetsMixin,
  SubscribersMixin,
  UsersMixin,
  ProductGroupsMixin,
  DiscountGroupsMixin,
  FormParamsMixin,
  ReviewsMixin,
  DeliveryGroupsMixin,
  DeliveryMixin,
  DeliveryPricesMixin,
  DefaultDeliveryRegionsMixin,
  SeoPagesMixin,
  WordCasesMixin,
  WordAdjectivesMixin,
  TemplatesMixin,
  BasicReportMixin,
  WordPluralsMixin,
  WordReplacesMixin,
  GenerateMixin,
  RobotsMixin,
  EmailTemplatesMixin,
  PointsMixin,
  RegionsMixin,
  PaymentsMixin,
  OrderStatusGroupsMixin,
  StatusQualityMixin,
  OrderStatusesMixin,
  ConfigMixin,
  PriceRoleAssignmentsMixin,
  PartnersMixin,
  CurrenciesMixin,
  ProductFixedMixin,
  ReindexerMixin,
  OrderBillsMixin,
  OrderOffersMixin,
  ManagersMixin,
  GroupsParamsMixin,
  ProductOffersMixin,
  OfferMixin,
  MainPageMixin,
  StaticPagesMixin,
  PermissionsMixin,
  BonusMixin,
  BonusAccountMixin,
  BonusLogMixin,
  BonusTypeMixin,
  SeoTextsMixin,
  SearchTextReplaceMixin,
  FeedsMixin,
  FilesMixin,
  PromoCodesMixin,
  CustomFiltersMixin,
  UserTypesMixin,
  CollectionsMixin,
  EmailTemplateGroupsMixin,
  RafflesMixin,
  EventTriggersMixin,
  PageTemplatesMixin,
  TasksMixin,
  TaskStatusesMixin,
  TaskOptionsMixin,
  TaskUserOptionsMixin,
  Newsletters,
  ChatChannelsMixin,
  ChampionshipStagesMixin,
  ChampionshipTeamProfileFieldsMixin,
  ChampionshipCitiesMixin,
  ChampionshipCompetenciesMixin,
  ChampionshipAgeGroupMixin,
  ChampionshipAgeTypeMixin,
  WebsiteAssistantMixin,
  SurveysMixin,
  OrdersStatusesStatisticsMixin,
  ChampionshipActionMixin,
  ChampionshipChampionshipsMixin,
  ChampionshipPermissionMixin,
  RefundMixin
}

export {
  OrderCommentsMixin,
  SearchLogsMixin,
  SitesMixin,
  OrdersMixin,
  RolesMixin,
  PriceRolesMixin,
  CategoriesMixin,
  PagesMixin,
  FormsMixin,
  OptionsValuesMixin,
  BannerBlocksMixin,
  StickersMixin,
  ProductsMixin,
  BasketsMixin,
  NewsMixin,
  ListWaitingMixin,
  SearchTextReplaceMixin,
  WidgetsMixin,
  SubscribersMixin,
  BasicReportMixin,
  SeoTextsMixin,
  UsersMixin,
  ProductGroupsMixin,
  DiscountGroupsMixin,
  FormParamsMixin,
  ReviewsMixin,
  DeliveryGroupsMixin,
  DeliveryMixin,
  DeliveryPricesMixin,
  SeoPagesMixin,
  WordCasesMixin,
  WordAdjectivesMixin,
  TemplatesMixin,
  WordPluralsMixin,
  WordReplacesMixin,
  GenerateMixin,
  RobotsMixin,
  EmailTemplatesMixin,
  PointsMixin,
  RegionsMixin,
  PaymentsMixin,
  OrderStatusGroupsMixin,
  StatusQualityMixin,
  OrderStatusesMixin,
  ConfigMixin,
  ManagersMixin,
  GroupsParamsMixin,
  PriceRoleAssignmentsMixin,
  PartnersMixin,
  CurrenciesMixin,
  ProductFixedMixin,
  ReindexerMixin,
  OrderBillsMixin,
  OrderOffersMixin,
  ProductOffersMixin,
  OfferMixin,
  MainPageMixin,
  StaticPagesMixin,
  PermissionsMixin,
  BonusMixin,
  BonusAccountMixin,
  BonusLogMixin,
  BonusTypeMixin,
  FilesMixin,
  FeedsMixin,
  PromoCodesMixin,
  CustomFiltersMixin,
  UserTypesMixin,
  CollectionsMixin,
  EmailTemplateGroupsMixin,
  RafflesMixin,
  EventTriggersMixin,
  PageTemplatesMixin,
  TasksMixin,
  TaskStatusesMixin,
  TaskOptionsMixin,
  TaskUserOptionsMixin,
  Newsletters,
  ChatChannelsMixin,
  ChampionshipStagesMixin,
  ChampionshipTeamProfileFieldsMixin,
  ChampionshipCitiesMixin,
  ChampionshipCompetenciesMixin,
  ChampionshipAgeGroupMixin,
  ChampionshipAgeTypeMixin,
  WebsiteAssistantMixin,
  SurveysMixin,
  OrdersStatusesStatisticsMixin,
  ChampionshipActionMixin,
  ChampionshipChampionshipsMixin,
  ChampionshipPermissionMixin,
  RefundMixin
}
