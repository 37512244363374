export namespace NDataViewer {
  export interface IField<T> {
    name: keyof T
    alias?: string | null
  }

  export interface ISingleCondition<T, K extends keyof T> {
    field: K,
    op: T[K] extends typeof Array ? EArrayOp : EOp
    value: T[K]
  }

  export interface IMultipleCondition<T> {
    boolOp: EBoolOp,
    conditions: (IMultipleCondition<T> | ISingleCondition<T, keyof T>)[]
  }

  export interface IRequestSchema<T> {
    tableName: ETablesNames
    fields?: IField<T>[]
    sortBy?: keyof T
    sortDir?: ESortDir
    page?: number
    pageSize?: number
    conditions?: (ISingleCondition<T, keyof T> | IMultipleCondition<T>)[]
  }

  export interface IResponse<T> {
    data: T[],
    paginator: {
      currentPage: number,
      pageSize: number,
      totalItems: number,
      totalPages: number
    }
  }

  export type TDataFormat = 'json' | 'xlsx'

  export interface IPlugin {
    fetch<T>(schema: IRequestSchema<T>, format: TDataFormat | undefined): Promise<IResponse<T>>
  }
}

export enum ETablesNames {
  USERS_ADMIN_VIEW = 'users_admin_view',
  USER_TYPE = 'user_type',
  PRODUCTS_ADMIN_VIEW = 'products_admin_view',
  USERS_ACCOUNT_VIEW = 'related_accounts_admin_view',
  PRODUCTS_WITHOUT_DISCOUNT_GROUPS_VIEW = 'products_without_discount_groups_view',
  PAGES_ASSISTANTS_VIEW = 'pages_assistants_view',
  MANAGERS_ADMIN_VIEW = 'managers_admin_view',
  PROMO_CODE_ADMIN_VIEW = 'promo_code_admin_view',
  GAMIFICATION_ACHIEVEMENTS_VIEW = 'gamification.achievements'
}

export enum ESortDir {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum EOp {
  EQUAL = '=',
  NOT_EQUAL = '<>',
  GREATER = '>',
  GREATER_OR_EQUAL = '>=',
  LESS = '<',
  LESS_OR_EQUAL = '<=',
  IN = 'IN',
  LIKE = 'LIKE',
  IS = 'IS',
  IS_NOT = 'IS NOT'
}

export enum EArrayOp {
  INCLUDES = '@>',
  NOT_INCLUDES = '<@'
}

export enum EBoolOp {
  AND = 'AND',
  OR = 'OR',
  EQUAL = 'EQUAL'
}
